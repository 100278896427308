import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    options: Array
  }

  connect() {
    this.getComponents().then(({ Timeline, DataSet}) => {
      var items = new DataSet(this.optionsValue);

      // Configuration for the Timeline
      // https://visjs.github.io/vis-timeline/docs/timeline/
      var options = {
        template: function (item, element, data) {
          return `<a class="uk-link" href="/stream/${item.id}" target="_blank">${item.content}</a>`
        },
      };

      var timeline = new Timeline(this.element, items, options)
    })
  }

  async getComponents() {
    const { Timeline } = await import(/* webpackChunkName: "controls" */ "vis-timeline/esnext");
    const { DataSet } = await import(/* webpackChunkName: "controls" */ "vis-data/esnext");

    return { Timeline, DataSet }
  }
}
