import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
      options: Object
  }

  connect() {
    this.getComponents().then(({ echarts }) => {
      let instance = echarts.init(this.element, "white");
      instance.setOption(this.optionsValue)
    })
  }

  async getComponents() {
    const echarts = await import(/* webpackChunkName: "visuals" */ "echarts");

    return { echarts }
  }
}
