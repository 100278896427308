import htmx from 'htmx.org';
import hyperscript from 'hyperscript.org';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import 'lite-youtube-embed';
// lazy image loading
import 'lazysizes';

// loads the Icon plugin
UIkit.use(Icons);

window.htmx = htmx
window._hyperscript = hyperscript
window._hyperscript.browserInit()

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
